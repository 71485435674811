import "./index.css";

const Footer = () => {
    return (
        <>
            <div className="footer">
                <h5> 
                    <a href="https://www.instagram.com/adxrstudio/">ADXRStudio</a> © 2020 - 2023.
                </h5>
            </div>
        </>
    )
}

export default Footer;